import styled from 'styled-components';
import { Form } from '@unform/web';

import SimpleButton from '~/components/Button/Simple';

export const Container = styled.div`
  display: flex;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 575px) {
    padding: 10px 15px;
  }
`;

export const Content = styled.div`
  flex: 0 1 100%;
`;

export const Title = styled.h3`
  text-align: left;
`;

export const Subtitle = styled.h4`
  font-size: 2.3em;
  text-align: left;
  color: #222;

  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 250ms ease-in-out;
`;

export const Unform = styled(Form)`
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center;
  margin: 0 !important;

  min-width: 30%;
  max-width: 50%;

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    min-width: 50%;
    max-width: 75%;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    min-width: 75%;
    max-width: 80%;
  }

  @media only screen and (max-width: 575px) {
    min-width: 80%;
    max-width: 100%;
  }
`;

export const FieldsetForm = styled.fieldset`
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center;
  justify-content: flex-start;

  border: none !important;

  margin-bottom: 24px;
`;

export const FieldsetLegendForm = styled.legend`
  width: 100%;
  float: left;
  margin-bottom: 24px;

  font-size: 1.2rem;
  line-height: 1.2;
  text-align: left;

  border-bottom: 1px solid #555;
`;

export const FormInputContainer = styled.div`
  flex: 0 1 100%;
  padding: 0 15px;
  margin-bottom: 16px;
  text-align: left;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  input {
    display: block;
    width: 100%;
    margin: 0 0 4px !important;

    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  span {
    text-align: left;
  }
`;

export const FormInputButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  flex: 0 1 100%;
  padding: 0 15px;
  margin-bottom: 16px;
  text-align: left;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  input {
    flex: 0 1 80%;
    margin: 0 0 4px !important;

    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }

  button {
    flex: 0 1 auto;
    height: auto !important;
    margin-left: 4px !important;
    padding: 0 8px !important;

    font-size: 0.6rem !important;
    font-weight: normal;
  }

  span {
    text-align: left;
  }
`;

export const FormListContainer = styled.div`
  flex: 0 1 100%;
  padding: 0 15px;
  margin-bottom: 16px;
  text-align: left;

  li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    vertical-align: middle;

    width: 50%;
    padding: 2px 4px;
  }

  span {
    flex: 0 1 80%;
    margin: 0 !important;

    color: #555 !important;
    font-weight: normal !important;
    line-height: 1.4;
    text-align: left;
  }

  button {
    flex: 0 1 auto;
    height: auto !important;
    margin-left: 4px !important;
    padding: 0 8px !important;

    font-size: 0.6rem !important;
    font-weight: normal;
  }

  @media only screen and (max-width: 767px) {
    li {
      width: 100%;
    }
  }
`;

export const FormButtonContainer = styled.div`
  flex: 0 1 100%;
  padding: 0 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled(SimpleButton)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
