import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  place-items: center;

  background-color: rgba(0, 0, 0, 0.8);

  min-width: 100vw;
  min-height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;

  ${({ hidden }) => {
    if (hidden) {
      return 'display: none; opacity: 0;';
    }
    return 'display: grid; opacity: 1;';
  }};
`;

export const Body = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  width: 300px;
  height: 300px;

  border-radius: 0.4em;
`;

export const Loader = styled.div`
  border: 0.2em solid rgba(0, 0, 0, 0.4);
  border-top: 0.2em solid #212121;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.2em;
  animation: ${spin} 0.6s linear infinite;
`;
