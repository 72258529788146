export const setNewEvaluation = (data, history) => ({
  type: '@evaluation/NEW_EVALUATION',
  payload: { ...data, history },
});

export const startQuestionnaire = (data) => ({
  type: '@evaluation/START_QUESTIONNAIRE',
  payload: { ...data },
});

export const setEvaluation = (data) => {
  return {
    type: '@evaluation/SET_EVALUATION',
    payload: { ...data },
  };
};

export const setQuestionsPage = (data) => {
  return {
    type: '@evaluation/SET_PAGE',
    payload: { ...data },
  };
};

export const evaluationFailure = () => {
  return {
    type: '@evaluation/EVAL_FAIL',
  };
};

export const reportFailure = () => {
  return {
    type: '@evaluation/REPORT_FAIL',
  };
};
