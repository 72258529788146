import styled from 'styled-components';
import { Form } from '@unform/web';

export const Content = styled.div`
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Unform = styled(Form)`
  min-width: 100%;
`;
