import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 575px) {
    padding: 10px 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 0 1 100%;
`;

export const Title = styled.h3`
  flex: 0 1 100%;
  text-align: left;
`;

export const Subtitle = styled.h4`
  font-size: 2.3em;
  text-align: center;
  color: #222;

  margin-top: 0;
`;

export const Column = styled.div`
  flex: 0 1 25%;

  @media screen and (max-width: 567px) {
    flex: 0 1 100%;
  }
`;
