import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { signOut } from '../../store/modules/auth/actions';
import { Container, Content, Profile, Button } from './styles';
import logo from '~/assets/logo_branco.png';

export default function Header() {
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignOut = () => {
    try {
      dispatch(signOut());
      history.push('/');
    } catch (err) {
      toast.error(
        'Não foi possível sair do sistema. Tente novamente mais tarde.'
      );
    }
  };

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} alt="Grossi Saúde Integral" />
          {profile.provider ? (
            <>
              <NavLink to="/home">INÍCIO</NavLink>
              <NavLink to="/users">USUÁRIOS</NavLink>
              <NavLink to="/companies">EMPRESAS</NavLink>
              <NavLink to="/evaluations">AVALIAÇÕES</NavLink>
            </>
          ) : null}
        </nav>

        <aside>
          <Profile>
            <div>
              <strong>
                {`${profile?.name.split(' ')[0]} ${
                  profile?.name.split(' ')[profile?.name.split(' ').length - 1]
                }`}
              </strong>
              <Button color="default" align="right" onClick={handleSignOut}>
                SAIR
              </Button>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
