import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import DefaultLayout from '../_layouts/default';
import { store } from '~/store';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { signed } = store.getState().auth;
  return (
    <Route {...rest}>
      {signed ? (
        <DefaultLayout>
          <Component />
        </DefaultLayout>
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )}
    </Route>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
