import React from 'react';
import PropTypes from 'prop-types';
import * as HiIcon from 'react-icons/hi';

import { Container } from './styles';

const IconButton = ({ color, margin, icon, ...rest }) => {
  const Icon = HiIcon[icon];
  const isMobile = window.outerWidth > 575;

  return (
    <Container color={color} margin={margin} {...rest}>
      <Icon />
      {isMobile ? rest.label : null}
    </Container>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  margin: PropTypes.shape({}),
};

IconButton.defaultProps = {
  color: 'default',
  margin: {},
};

export default IconButton;
