import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import Api from '~/services/api';

import Card from '~/components/Card';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import { Input } from '~/components/Form';

import {
  Container,
  Content,
  Title,
  Subtitle,
  Body,
  Unform,
  FormInputContainer,
  Button,
} from './styles';
import { passChangeOpen } from '~/store/modules/password/actions';

export default function Users() {
  const dispatch = useDispatch();

  const formRef = useRef();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: null,
  });

  const findUsers = async (page = 1, name = null) => {
    setLoading(true);
    try {
      const { data } = await Api.get(
        `users?page=${page}${name !== null ? `&name=${name}` : ''}`
      );

      if (data.status !== 'success') {
        toast.error('Houve um erro ao listar as Avaliações.');
        setLoading(false);
        return false;
      }

      setList(data.data.data);
      setPagination({
        page,
        lastPage: data.data.lastPage,
      });
      setLoading(false);

      return true;
    } catch (err) {
      toast.error('Houve um erro ao listar os Usuários.');
      setLoading(false);
      return new Error('Erro ao listar os Usuários.');
    }
  };

  const removeUser = async (row) => {
    const { id } = row;

    if (!id) {
      toast.error(
        'Houve um erro ao tentar excluir o usuário. Favor atualizar a página e tentar novamente.'
      );

      return false;
    }

    try {
      const { data } = await Api.delete(`/users/${id}`);

      if (data.status !== 'success') {
        toast.error(
          'Houve um erro ao tentar excluir o usuário. Favor atualizar a página e tentar novamente.'
        );
        return false;
      }

      toast.warn('Cliente removido com sucesso.');
      findUsers();
      return true;
    } catch (err) {
      toast.error(
        'Houve um erro ao tentar excluir o usuário. Favor atualizar a página e tentar novamente.'
      );

      return false;
    }
  };

  const activateUser = async (row) => {
    const { id } = row;

    if (!id) {
      toast.error(
        'Houve um erro ao tentar reativar o usuário. Favor atualizar a página e tentar novamente.'
      );

      return false;
    }

    try {
      const { data } = await Api.get(`/users/reactivate/${id}`);

      if (data.status !== 'success') {
        toast.error(
          'Houve um erro ao tentar reativar o usuário. Favor atualizar a página e tentar novamente.'
        );
        return false;
      }

      toast.success('Cliente reativado com sucesso.');
      findUsers();
      return true;
    } catch (err) {
      toast.error(
        'Houve um erro ao tentar reativar o usuário. Favor atualizar a página e tentar novamente.'
      );

      return false;
    }
  };

  const openPasswordModal = (row) => {
    dispatch(passChangeOpen(row));
  };

  const handlePageChange = (page) => {
    if (page && page >= 1) {
      findUsers(page);
    }
  };

  const handleFilter = ({ filterName }) => {
    let name = null;

    if (filterName && filterName !== null && filterName !== '') {
      name = filterName;
    }

    findUsers(1, name);
  };

  const handleReset = () => {
    findUsers();

    formRef.current.reset();
  };

  useEffect(() => {
    findUsers();
    return () => {};
  }, []);

  const headers = [
    {
      title: 'Nome',
      label: 'name',
      type: 'string',
      align: 'left',
    },
    {
      title: 'E-mail',
      label: 'email',
      type: 'string',
      align: 'left',
    },
    {
      title: 'Aniversário',
      label: 'birthday',
      type: 'date',
      format: 'DD/MM/YYYY',
    },
    {
      title: 'Adminstrador',
      label: 'provider',
      type: 'bool',
    },
    {
      title: 'Ativo',
      label: 'active',
      type: 'bool',
    },
    {
      title: 'Ações',
      actions: [
        {
          type: 'icon',
          label: '',
          title: 'Desativar',
          icon: 'HiXCircle',
          color: 'danger',
          disabled: (r) => !r.active,
          onClick: (r) => removeUser(r),
        },
        {
          type: 'icon',
          label: '',
          title: 'Reativar',
          icon: 'HiCheckCircle',
          color: 'success',
          disabled: (r) => r.active,
          onClick: (r) => activateUser(r),
        },
        {
          type: 'icon',
          label: '',
          title: 'Trocar Senha',
          icon: 'HiKey',
          color: 'warning',
          disabled: (r) => !r.active,
          onClick: (r) => openPasswordModal(r),
        },
      ],
    },
  ];

  const makeLoadingCard = () => {
    return (
      <Body>
        <Loading />
      </Body>
    );
  };
  const makeTableCard = () => {
    return (
      <>
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
        <Table headers={headers} data={list} />
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
      </>
    );
  };

  return (
    <Container>
      <Content>
        <Title>Lista de Usuários</Title>

        <Card>
          <>
            <Subtitle>Filtros</Subtitle>
            <Unform ref={formRef} onSubmit={handleFilter} onReset={handleReset}>
              <FormInputContainer>
                <Input
                  className="input-default"
                  label="Nome:"
                  name="filterName"
                  placeholder="Nome do Cliente"
                />
              </FormInputContainer>

              <Button
                type="submit"
                color="grossi"
                margin={{ top: '45px', bottom: '15px' }}
              >
                Filtrar
              </Button>
              <Button
                type="reset"
                color="default"
                margin={{ top: 0, bottom: 0, left: '5px' }}
              >
                Limpar
              </Button>
            </Unform>
          </>
        </Card>

        <Card>{loading ? makeLoadingCard() : makeTableCard()}</Card>
      </Content>
    </Container>
  );
}
