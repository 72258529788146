export const passChangeOpen = (data) => ({
  type: '@user/PASS_CHANGE_OPEN',
  payload: { ...data },
});

export const passChangeRequest = (data) => ({
  type: '@user/PASS_CHANGE_REQUEST',
  payload: { ...data },
});

export const passChangeSuccess = () => ({
  type: '@user/PASS_CHANGE_SUCCESS',
});

export const passChangeFail = () => ({
  type: '@user/PASS_CHANGE_FAIL',
});
