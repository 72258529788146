import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './styles';

const FlowText = ({ children, align }) => {
  return <Text align={align}>{children}</Text>;
};

FlowText.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
};

FlowText.defaultProps = {
  align: 'initial',
};

export default FlowText;
