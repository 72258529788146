import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import logo from '~/assets/logo.png';

import Api from '~/services/api';
import StringToDate from '~/helpers/StringToDate';
import { signUpRequest } from '~/store/modules/auth/actions';

import { Input, MaskedInput } from '~/components/Form';
import Select from '~/components/Form/Select';
import SwitchInput from '~/components/Form/Switch';

import {
  Image,
  Text,
  Unform,
  FormInputContainer,
  FormButtonContainer,
  Button,
} from './styles';

function SignUp() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const [companyId, setCompanyId] = useState(null);
  const [companyAreaOptions, setCompanyAreaOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const options = [
    {
      id: 'TermSwitch',
      label: `Tenho mais de 18 anos de idade e li e entendi os <a href="/Termos_e_Condições_Grossi.pdf" target="_blank">Termos e Condições</a>
      deste contrato e entendo que algumas das perguntas da pesquisa podem se
      referir a dados confidenciais.`,
      value: true,
    },
  ];

  const findCompany = async () => {
    try {
      const { data } = await Api.get(`companies/token/${token}`);

      if (data.status !== 'success') {
        toast.error('Houve um erro ao listar a Empresa.');

        return false;
      }

      const { data: companyData } = data;

      setCompanyId(companyData.id);
      setCompanyAreaOptions(companyData.InternAreas);

      formRef.current.setFieldValue('company', companyData.fantasyName);

      return true;
    } catch (err) {
      toast.error(
        'Houve um erro ao listar a Empresa ou o token não foi reconhecido.'
      );

      history.replace('/home');

      return new Error('Erro ao listar as Empresas.');
    }
  };

  const handleSuccessForm = useCallback(() => {
    history.replace('/home');
  }, []);

  const fetchMobile = () => {
    const width = window.outerWidth;

    if (width <= 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleSubmit = async ({
    companyArea,
    fullName,
    email,
    birth,
    password,
    confirmPassword,
    terms,
  }) => {
    const schema = Yup.object().shape({
      companyArea: Yup.number().when('$other', (other) => {
        if (other !== null && typeof other !== 'undefined') {
          return Yup.number().required('Selecione uma Área Interna');
        }
      }),
      fullName: Yup.string().required(
        'Informar o seu nome completo é obrigatório.'
      ),
      email: Yup.string()
        .email('Informe um e-mail válido.')
        .required('Informar o seu e-mail é obrigatório.'),
      birthday: Yup.date('Informe uma data válida.').required(
        'Informar a sua data de nascimento é obrigatório.'
      ),
      password: Yup.string()
        .min(6, 'A senha precisa de no mínimo 6 caractéres.')
        .required('Informar uma senha é obrigatório.'),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref('password')],
          'A confirmação de senha informada não confere com a senha.'
        )
        .required('Informar a confirmação da senha é obrigatório.'),
      term: Yup.bool().oneOf([true], 'O Termo deve ser aceito.'),
    });

    try {
      formRef.current.setErrors({});
      const birthday = StringToDate(birth);

      await schema.validate(
        {
          companyArea,
          fullName,
          email,
          birthday,
          password,
          confirmPassword,
          terms,
        },
        {
          abortEarly: false,
          context: {
            other: token,
          },
        }
      );

      dispatch(
        signUpRequest(
          {
            companyId,
            companyArea,
            fullName,
            email,
            birthday,
            password,
            confirmPassword,
            terms: terms[0],
          },
          handleSuccessForm
        )
      );
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (token) {
      findCompany();

      fetchMobile();
    }
  }, []);

  return (
    <>
      <Image src={logo} alt="Logo Grossi" />

      <Unform ref={formRef} onSubmit={handleSubmit}>
        {token ? (
          <>
            <FormInputContainer grid={token ? 6 : 12}>
              <Input
                name="company"
                type="text"
                label="Empresa:"
                placeholder="Empresa que você trabalha"
                disabled
                readonly
              />
            </FormInputContainer>

            <FormInputContainer grid={token ? 6 : 12}>
              <Select
                name="companyArea"
                label="Área:"
                placeholder="Área Interna que você trabalha"
                maxMenuHeight={isMobile ? 175 : 300}
                options={companyAreaOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </FormInputContainer>
          </>
        ) : null}
        <FormInputContainer>
          <Input
            name="fullName"
            type="text"
            label="Seu Nome Completo:"
            placeholder="Seu nome completo"
          />
        </FormInputContainer>

        <FormInputContainer grid={token ? 6 : 12}>
          <Input
            name="email"
            type="email"
            label="Seu melhor E-mail:"
            placeholder="Seu melhor e-mail"
          />
        </FormInputContainer>

        <FormInputContainer grid={token ? 6 : 12}>
          <MaskedInput
            name="birth"
            label="Sua Data de Nascimento:"
            mask="99/99/9999"
            placeholder="dd/mm/aaaa"
          />
        </FormInputContainer>

        <FormInputContainer grid={token ? 6 : 12}>
          <Input
            name="password"
            type="password"
            label="Uma senha bem segura:"
            placeholder="Sua senha"
          />
        </FormInputContainer>

        <FormInputContainer grid={token ? 6 : 12}>
          <Input
            name="confirmPassword"
            type="password"
            label="Confirme sua senha:"
            placeholder="Confirme sua senha"
          />
        </FormInputContainer>

        <Text>
          Sua privacidade é importante para nós. Pedimos seu e-mail para criar
          uma conta para que você possa salvar seu progresso. Não compartilhamos
          nossas listas de e-mail e seus resultados são privados e
          confidenciais. Veja nossa{' '}
          <a href="/Termos_e_Condições_Grossi.pdf" target="_blank">
            Política de Privacidade
          </a>
          .
        </Text>
        <SwitchInput name="terms" options={options} />

        <FormButtonContainer>
          <Button
            type="submit"
            color="success"
            margin={{ top: '45px', bottom: '15px' }}
          >
            Cadastrar
          </Button>
        </FormButtonContainer>

        <FormButtonContainer>
          <Link to="/">Já tenho login</Link>
        </FormButtonContainer>
      </Unform>
    </>
  );
}

export default SignUp;
