import React from 'react';
import PropTypes from 'prop-types';

import { Container, Item, Action } from './styles';

function Pagination({ currentPage, totalPages, onPageChanged }) {
  const realTotalPages = typeof totalPages === 'number' ? totalPages : 0;

  /**
   * Helper method for creating a range of numbers
   * range(1, 5) => [1, 2, 3, 4, 5]
   */
  const pagesRange = (from, to, step = 1) => {
    let i = from;
    const range = [];

    if (from < totalPages) {
      to = to < totalPages ? to : totalPages;

      while (i <= to) {
        range.push(i);
        i += step;
      }
    } else {
      to = from;
      i = from - 4;

      while (i <= to) {
        range.push(i);
        i += step;
      }
    }

    return range;
  };

  if (!realTotalPages || realTotalPages <= 1) {
    // don't display pager if there is only 1 page
    return null;
  }

  return (
    <Container>
      <Item className={currentPage === 1 ? 'disabled' : ''}>
        <Action type="button" onClick={() => onPageChanged(1)}>
          Primeira
        </Action>
      </Item>
      <Item className={currentPage === 1 ? 'disabled' : ''}>
        <Action type="button" onClick={() => onPageChanged(currentPage - 1)}>
          Ant.
        </Action>
      </Item>
      {currentPage > 1 ? (
        <Item className={currentPage === totalPages ? 'disabled' : ''}>
          <Action
            type="button"
            onClick={() => onPageChanged(currentPage > 5 ? currentPage - 5 : 1)}
          >
            ...
          </Action>
        </Item>
      ) : null}
      {pagesRange(currentPage, currentPage + 4).map((page) => (
        <Item key={page} className={currentPage === page ? 'active' : ''}>
          <Action type="button" onClick={() => onPageChanged(page)}>
            {page}
          </Action>
        </Item>
      ))}
      {currentPage < totalPages - 4 ? (
        <Item className={currentPage === totalPages ? 'disabled' : ''}>
          <Action type="button" onClick={() => onPageChanged(currentPage + 5)}>
            ...
          </Action>
        </Item>
      ) : null}

      <Item className={currentPage === totalPages ? 'disabled' : ''}>
        <Action type="button" onClick={() => onPageChanged(currentPage + 1)}>
          Próx.
        </Action>
      </Item>
      <Item className={currentPage === totalPages ? 'disabled' : ''}>
        <Action type="button" onClick={() => onPageChanged(totalPages)}>
          Última
        </Action>
      </Item>
    </Container>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
};

Pagination.defaultProps = {};

export default Pagination;
