import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { stringToCnpj } from '../../../helpers/Formatters';

import IconButton from '../../Button/Icon';

import { Container, Row, Cell } from './styles';

const Body = ({ data, headers }) => {
  const TextCell = (h, r) => {
    switch (h.type) {
      case 'string':
        return (
          <Cell
            key={`${h.title}-${r[h.label]}${r.id}`}
            align={h.align || 'center'}
          >
            {r[h.label] ? r[h.label].toUpperCase() : ''}
          </Cell>
        );

      case 'date':
        return (
          <Cell
            key={`${h.title}-${r[h.label]}${r.id}`}
            align={h.align || 'center'}
          >
            {moment(r[h.label]).format(h.format)}
          </Cell>
        );

      case 'bool':
        return (
          <Cell
            key={`${h.title}-${r[h.label]}${r.id}`}
            align={h.align || 'center'}
          >
            {r[h.label] ? 'SIM' : 'NÃO'}
          </Cell>
        );

      case 'cnpj':
        return (
          <Cell
            key={`${h.title}-${r[h.label]}${r.id}`}
            align={h.align || 'center'}
          >
            {stringToCnpj(r[h.label])}
          </Cell>
        );

      default:
        return (
          <Cell
            key={`${h.title}-${r[h.label]}${r.id}`}
            align={h.align || 'center'}
          >
            {r[h.label]}
          </Cell>
        );
    }
  };

  const IconButtonCell = (h, r, a, i) => (
    <Cell
      key={`${h.title}-Button-${a.type}-${r.id}-${i}`}
      align={h.align || 'center'}
      width="10%"
    >
      <IconButton
        color={a.color || 'default'}
        icon={a.icon}
        title={a.title || a.label}
        label={a.label}
        disabled={a.disabled ? a.disabled(r) : false}
        onClick={() => a.onClick(r)}
      />
    </Cell>
  );

  const TextButtonCell = (h, r, a, i) => (
    <Cell
      key={`${h.title}-${r[h.label]}-Button-${a.type}-${r.id}-${i}`}
      align={h.align || 'center'}
      maxWidth="80px"
    >
      {r[h.label].toUpperCase()}
    </Cell>
  );

  const MakeCell = (h, r) => {
    if (!h.actions) {
      return TextCell(h, r);
    }

    return h.actions.map((a, i) =>
      a.type === 'icon'
        ? IconButtonCell(h, r, a, i)
        : TextButtonCell(h, r, a, i)
    );
  };

  return (
    <Container>
      {data.map((r) => (
        <Row key={r.id}>{headers.map((h) => MakeCell(h, r))}</Row>
      ))}
    </Container>
  );
};

Body.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Body;
