import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import questionnaire from './questionnaire/reducer';
import report from './report/reducer';
import password from './password/reducer';
import company from './company/reducer';

export default combineReducers({
  auth,
  user,
  questionnaire,
  report,
  password,
  company,
});
