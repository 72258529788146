import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#fff';

      case 'info':
        return '#fff';

      case 'success':
        return '#fff';

      case 'warning':
        return 'rgba(0,0,0,.7)';

      case 'danger':
        return '#fff';

      default:
        return '#363636';
    }
  }};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${({ color }) =>
    color !== 'default' ? 'transparent' : '#dbdbdb'};
  background-color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#00d1b2 !important';

      case 'info':
        return '#3298dc !important';

      case 'success':
        return '#48c774 !important';

      case 'warning':
        return '#ffdd57 !important';

      case 'danger':
        return '#f14668 !important';

      case 'grossi':
        return '#f96d00 !important';

      default:
        return '#555 !important';
    }
  }};

  height: 2.5em;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  margin: ${({ margin }) => {
    return `${margin.top ? margin.top : 0} ${margin.right ? margin.right : 0} ${
      margin.bottom ? margin.bottom : '25px'
    } ${margin.left ? margin.left : 0} !important`;
  }};

  cursor: pointer;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  &:disabled {
    cursor: wait;
    background-color: #c3c3c3 !important;
    color: #555;
  }

  &:hover {
    background-color: ${({ color }) => {
      switch (color) {
        case 'primary':
          return `${lighten(0.03)('#00d1b2')}!important`;

        case 'info':
          return `${lighten(0.03)('#3298dc')}!important`;

        case 'success':
          return `${lighten(0.03)('#48c774')}!important`;

        case 'warning':
          return `${lighten(0.03)('#ffdd57')}!important`;

        case 'danger':
          return `${lighten(0.03)('#f14668')}!important`;

        case 'grossi':
          return `${lighten(0.03)('#f96d00')}!important`;

        default:
          return `${lighten(0.03)('#555')}!important`;
      }
    }};

    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
      0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }
`;
