import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

const Select = ({ name, label, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.id;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <label htmlFor={name}>{label}</label>

      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        styles={{
          control: (provided) => ({
            // none of react-select's styles are passed to <Control />
            ...provided,
            height: 36,
          }),
          valueContainer: (provided) => ({
            ...provided,
            height: 36,
            paddingTop: 6,
            paddingBottom: 6,
          }),
          singleValue: (provided) => ({
            ...provided,
            transform: 'unset',
          }),
        }}
        {...rest}
      />

      {error && <span>{error}</span>}
    </>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Select;
