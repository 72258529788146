import styled from 'styled-components';

import FlowText from '~/components/Text/FlowText';
import SimpleButton from '~/components/Button/Simple';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 575px) {
    padding: 10px 15px;
  }
`;

export const Title = styled.h3`
  color: #111;
  text-align: center;
  margin-bottom: 35px;
`;

export const Text = styled(FlowText)`
  color: #111;
`;

export const Button = styled(SimpleButton)`
  max-width: 300px;
`;
