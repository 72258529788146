import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import questionnaire from './questionnaire/sagas';
import password from './password/sagas';
import company from './company/sagas';

export default function* rootSaga() {
  return yield all([auth, user, questionnaire, password, company]);
}
