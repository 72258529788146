import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { InputContainer } from './styles';

export default function Input({
  name,
  type,
  placeholder,
  label,
  className,
  ...rest
}) {
  const inputRef = useRef();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <InputContainer
        {...rest}
        className={className}
        id={name}
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />

      {error && <span>{error}</span>}
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  className: '',
};
