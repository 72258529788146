import styled from 'styled-components';
import { Form } from '@unform/web';

import SimpleButton from '~/components/Button/Simple';

export const Container = styled.div`
  display: flex;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 575px) {
    padding: 10px 15px;
  }
`;

export const Content = styled.div`
  flex: 0 1 100%;
`;

export const Title = styled.h3`
  text-align: left;
`;

export const Subtitle = styled.h4`
  font-size: 2.3em;
  text-align: left;
  color: #222;

  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 250ms ease-in-out;
`;

export const Unform = styled(Form)`
  flex-direction: row !important;
  align-items: center;
  margin: 0 !important;

  @media only screen and (max-width: 575px) {
    min-width: 100%;
  }
`;

export const FormInputContainer = styled.div`
  width: 30%;
  padding: 0 15px;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  input {
    display: block;
    width: 100%;
  }
`;

export const Button = styled(SimpleButton)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const TextAreaHidden = styled.textarea`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;
