import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Content = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  color: #222;
`;
