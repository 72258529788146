import styled from 'styled-components';

export const Container = styled.ul`
  display: inline-block;
  width: 100%;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
`;

export const Item = styled.li`
  display: inline;

  &.disabled > button,
  &.disabled > button:focus,
  &.disabled > button:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }

  &.active > button,
  &.active > button:focus,
  &.active > button:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #f96d00;
    border-color: #f96d00;
  }

  &:first-child > button {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child > button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const Action = styled.button`
  position: relative;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #f96d00;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
`;
