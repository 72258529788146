import styled from 'styled-components';

export const List = styled.ul`
  margin: 12px 0px;
  padding: 0;
  list-style: none;
  width: 100%;
`;

export const Item = styled.li`
  margin: 16px 0;
  position: relative;
`;

export const Switch = styled.input`
  --active: #fff;
  --active-inner: #fb7105;
  --focus: 2px rgba(0, 0, 0, 0.3);
  --border: #cfcfcf;
  --border-hover: #fff;
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;

  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  width: 10%;
  height: 21px !important;
  outline: none !important;
  display: inline-block !important;
  vertical-align: middle !important;
  position: relative !important;
  margin: 0 !important;
  cursor: pointer !important;
  border: 1px solid var(--bc, var(--border)) !important;
  background: var(--b, var(--background)) !important;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }

    & + label {
      color: #cccccc;
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &.switch {
    width: 38px;
    border-radius: 11px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 14px !important;
  line-height: 20px;
  text-align: justify !important;
  vertical-align: top;
  width: calc(90% - 15px);
  cursor: pointer !important;
  margin-left: 15px;
  color: #fff;

  a {
    text-decoration: underline;
  }

  @media only screen and (max-width: 575px) {
    width: calc(90% - 45px);
  }
`;
