import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { passChangeFail, passChangeSuccess } from './actions';

export function* changePassword({ payload }) {
  const { id, email, newPassword, confirmPassword } = payload;

  try {
    if (!id || !email) {
      throw new Error(
        'Houve um erro ao tentar trocar a senha do usuário. Favor atualizar a página e tentar novamente.'
      );
    }

    if (!newPassword || !confirmPassword) {
      throw new Error(
        'A senha ou a confirmação da senha não foram preenchidas.'
      );
    }

    const { data } = yield call(api.put, `/users/password/${id}`, {
      email,
      newPassword,
      confirmPassword,
    });

    if (data.status !== 'success') {
      throw new Error(
        'Houve um erro ao tentar trocar a senha do usuário. Favor atualizar a página e tentar novamente.'
      );
    }

    toast.success('Senha do cliente alterada com sucesso.');
    yield put(passChangeSuccess());
  } catch (err) {
    toast.error(err);

    yield put(passChangeFail());
  }
}

export default all([takeLatest('@user/PASS_CHANGE_REQUEST', changePassword)]);
