import styled from 'styled-components';
import { complement, darken, readableColor } from 'polished';

export const Container = styled.div`
  min-height: 100%;
  max-height: 100vh;
  background-image: linear-gradient(-60deg, #ff6600 0%, #f09819 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`;

export const Content = styled.div`
  position: relative;
  flex: 0 1 100vh;

  min-width: 75%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 315px;
    width: 75%;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 35%;
    margin-top: 30px;
    margin-bottom: 30px;

    label {
      color: #f2f2f2;
      left: 0;
      font-size: 1rem;
      cursor: text;
      text-align: initial;
      -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: transform 0.2s ease-out, color 0.2s ease-out;
      transition: transform 0.2s ease-out, color 0.2s ease-out,
        -webkit-trans2orm 0.2s ease-out;
    }

    input,
    select {
      font-family: 'Roboto', sans-serif;
      -moz-appearance: none;
      -webkit-appearance: none;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: none;
      display: inline-flex;
      font-size: 1rem;
      height: 2.5em;
      justify-content: flex-start;
      line-height: 1.5;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      margin: 0 0 10px;
      position: relative;
      vertical-align: top;
      background-color: #fff;
      border-color: #dbdbdb;
      color: #363636;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.15);
      max-width: 100%;
      width: 100%;

      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    span {
      color: ${readableColor('#ff6600', '#600', '#f66')};
      align-self: flex-start;
      margin: 0 0 12.5px;
      font-weight: bold;
    }

    button {
      margin: 5px 0 10px;
      height: 44px;
      background: ${complement('#ff6600')};
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, complement('#ff6600'))};
      }
    }

    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
