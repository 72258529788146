import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'login', {
      email,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));
    payload.callback();
  } catch (error) {
    console.log(error.response.message)
    toast.error('Falha na autenticação, verifique seus dados.');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      companyId,
      companyArea,
      fullName,
      email,
      birthday,
      password,
      confirmPassword,
      terms,
    } = payload;

    let response;

    if (!companyId) {
      response = yield call(api.post, 'users', {
        fullName,
        email,
        birthday,
        password,
        confirmPassword,
        terms,
      });
    } else {
      response = yield call(api.post, 'users/company', {
        companyId,
        companyArea,
        fullName,
        email,
        birthday,
        password,
        confirmPassword,
        terms,
      });
    }

    if (response.data.status === 'success') {
      toast.success('Cadastro realizado com sucesso.');
      payload.callback();
    }
  } catch (error) {
    toast.error('Falha no cadastro, verifique seus dados.');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload || !payload.auth) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
]);
