export const reportProcessStart = () => {
  return {
    type: '@report/REPORT_PROCESS_START',
  };
};

export const reportProcessFinish = () => {
  return {
    type: '@report/REPORT_PROCESS_FINISH',
  };
};
