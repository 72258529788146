import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@unform/core';

import { InputContainer } from './styles';

const MaskedInput = ({ name, placeholder, label, className, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <InputContainer
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={className}
        {...rest}
      />

      {error && <span>{error}</span>}
    </>
  );
};

MaskedInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MaskedInput.defaultProps = {
  className: '',
};

export default MaskedInput;
