import React, { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Card from '../../Card';
import { Input } from '../../Form';
import Select from '../../Form/Select';
import SimpleButton from '../../Button/Simple';

import Api from '../../../services/api';
import { startQuestionnaire } from '~/store/modules/questionnaire/actions';

import { Content, Unform } from './styles';

const Start = () => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [challengesOptions, setChallengesOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const schema = Yup.object().shape({
    profession: Yup.string().required(),
    challenge: Yup.number().required(),
  });

  const fetchChallenges = async () => {
    try {
      const { data } = await Api.get('challenges');

      if (data.status === 'success') {
        setChallengesOptions(data.data.challenges);
      }

      return true;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  const fetchMobile = () => {
    const width = window.outerWidth;

    if (width <= 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleSubmit = async (fields) => {
    try {
      await schema.validate(fields, { abortEarly: false });

      dispatch(startQuestionnaire(fields));
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    fetchChallenges();

    fetchMobile();
    return () => {};
  }, []);

  return (
    <Card>
      <Content>
        <Unform
          ref={formRef}
          style={{ marginBottom: 0 }}
          onSubmit={handleSubmit}
        >
          <Input
            className="input-default"
            type="text"
            name="profession"
            label="Profissão:"
            placeholder="Informe sua Profissão"
          />

          <Select
            name="challenge"
            label="Qual seu maior desafio:"
            placeholder="Selecione um desafio"
            maxMenuHeight={isMobile ? 175 : 300}
            options={challengesOptions}
            getOptionLabel={(option) => option.statement}
            getOptionValue={(option) => option.id}
          />

          <SimpleButton
            type="submit"
            color="success"
            margin={{ top: '100px', bottom: 0 }}
          >
            Salvar e começar
          </SimpleButton>
        </Unform>
      </Content>
    </Card>
  );
};

Start.propTypes = {};

export default Start;
