import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import socket from 'socket.io-client';

import Api from '~/services/api';

import Card from '~/components/Card';

import { reportFailure } from '../../store/modules/questionnaire/actions';

import { Container, Title, Text, Button } from './styles';

function Report() {
  const url =
    process.env.NODE_ENV !== 'development'
      ? `${process.env.REACT_APP_API}pdfs/`
      : 'http://localhost:3333/pdfs/';

  const { token } = useSelector((state) => state.auth);
  const { id } = useSelector((state) => state.questionnaire);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);

  const makeReport = async () => {
    try {
      const { data } = await Api.get(`evaluations/report/${id}`);
      if (data.status === 'success') {
        const io = socket(
          process.env.NODE_ENV !== 'development'
            ? `${process.env.REACT_APP_API}`
            : 'http://localhost:3333',
          {
            query: {
              Authorization: token,
            },
            transport: ['websocket'],
          }
        );

        io.on(data.channel, (message) => {
          if (message) {
            if (message.status === 'success') {
              setFile(message.file);
              setLoading(false);
            } else if (message.status === 'failure') {
              setFile(null);
              setError(true);
              setLoading(false);
            }
          }
        });
      } else if (data.status === 'failure') {
        toast.error(
          'Não foi possível gerar o relatório. Favor repetir o questionário.'
        );
        setLoading(false);
        dispatch(reportFailure());
        history.push('/home');
      }
    } catch (err) {
      toast.error(
        'Não foi possível gerar o relatório. Favor repetir o questionário.'
      );
      setLoading(false);
      dispatch(reportFailure());
      history.push('/home');
    }
  };

  const buttonText = () => {
    return error
      ? 'Erro ao gerar o relatório, favor recarregar a página ou tentar mais tarde.'
      : 'Visualizar Relatório';
  };

  const goToReport = () => {
    if (error) {
      document.location.reload();
    } else {
      window.open(url + file, '_blank');
    }
  };

  useEffect(() => {
    makeReport();
    return () => {};
  }, []);

  return (
    <Container>
      <Card>
        <>
          <Title>Parabéns, você finalizou o questionário!</Title>
          <Text>
            Estamos gerando para você o relatório de feedback e análise de suas
            competências. Aguarde um momento e assim que finalizado, clique no
            botão abaixo para visualizar.
          </Text>
          <Button
            color={error ? 'danger' : 'primary'}
            margin={{ top: '40px', bottom: '0' }}
            onClick={goToReport}
            disabled={loading}
          >
            {loading ? 'Gerando Relatório...' : buttonText()}
          </Button>
        </>
      </Card>
    </Container>
  );
}

export default Report;
