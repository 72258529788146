import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';

export const InputContainer = styled(ReactInputMask)`
  &.input-default {
    display: block;

    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    margin-top: 5px;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    background-color: #fff;
    background-clip: padding-box;

    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::placeholder {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.5;
      color: rgba(150, 150, 150, 0.6);
    }
  }
`;
