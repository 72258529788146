import styled from 'styled-components';
import { complement, darken, readableColor } from 'polished';

export const Container = styled.div`
  min-height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 315px;
    width: 75%;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;

    input {
      background: rgba(0, 0, 0, 0.3);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    span {
      color: ${readableColor('#ff6600', '#600', '#f66')};
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    button {
      margin: 5px 0 10px;
      height: 44px;
      background: ${complement('#ff6600')};
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, complement('#ff6600'))};
      }
    }

    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 65px);
  padding-top: 10px;
  text-align: center;
  overflow-y: auto;
  z-index: 1;
`;

export const Background = styled.div`
  position: absolute;
  max-width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0 !important;

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    max-height: 100%;
    opacity: 0.25;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
    z-index: 0 !important;
  }
`;
