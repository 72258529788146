import produce from 'immer';

const INITIAL_STATE = {
  open: false,
  data: {},
};

export default function password(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@user/PASS_CHANGE_OPEN':
      return produce(state, (draft) => {
        draft.open = true;
        draft.data = action.payload;
      });
    case '@user/PASS_CHANGE_SUCCESS':
      return produce(state, (draft) => {
        draft.open = false;
        draft.data = {};
      });
    case '@user/PASS_CHANGE_FAIL':
      console.tron.log('Called');
      return produce(state, (draft) => {
        draft.open = false;
        draft.data = {};
      });
    default:
      return state;
  }
}
