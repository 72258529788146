import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const LinkButton = ({ children, color, margin, path }) => {
  return (
    <Container to={path} color={color} margin={margin}>
      {children}
    </Container>
  );
};

LinkButton.propTypes = {
  children: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  color: PropTypes.string,
  margin: PropTypes.shape({}),
};

LinkButton.defaultProps = {
  color: 'default',
  margin: {},
};

export default LinkButton;
