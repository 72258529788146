import moment from 'moment';

const StringToDate = (string) => {
  const array = string.split('/');
  const stringFormatted = `${array[2]}-${array[1]}-${array[0]} 00:00:00-0300`;

  return moment(stringFormatted);
};

export default StringToDate;
