import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import { Input } from '~/components/Form';
import SimpleButton from '~/components/Button/Simple';

import logo from '~/assets/logo.png';
import { Unform } from './styles';

function SignInPage() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const history = useHistory();

  const handleSuccessForm = useCallback(() => {
    history.replace('/home');
  }, []);

  const handleSubmit = async ({ email, password }) => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email('Insira um e-mail válido.')
        .required('O e-mail é obrigatório.'),
      password: Yup.string().min(6).required('A senha é obrigatória'),
    });

    try {
      formRef.current.setErrors({});

      await schema.validate(
        { email, password },
        {
          abortEarly: false,
        }
      );

      dispatch(signInRequest({ email, password }, handleSuccessForm));
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <img src={logo} alt="Logo Grossi" />

      <Unform ref={formRef} onSubmit={handleSubmit}>
        <Input
          type="email"
          name="email"
          label="E-mail:"
          placeholder="Informe seu E-mail"
        />
        <Input
          type="password"
          name="password"
          label="Senha:"
          placeholder="Informe sua senha"
        />

        <SimpleButton
          type="submit"
          color="success"
          margin={{ top: '20px', bottom: '15px' }}
        >
          {loading ? 'Carregando...' : 'Acessar'}
        </SimpleButton>
        <Link to="/register">Criar conta gratuita</Link>
      </Unform>
    </>
  );
}

export default SignInPage;
