import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Input } from '~/components/Form';
import SimpleButton from '~/components/Button/Simple';

import { Container, Body, Content, Unform, IButton } from './styles';
import {
  passChangeFail,
  passChangeRequest,
} from '~/store/modules/password/actions';

function PasswordChange() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { open, data } = useSelector((state) => state.password);

  const handleSubmit = async ({ email, newPassword, confirmPassword }) => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email('Informe um e-mail válido.')
        .required('Informar o seu e-mail é obrigatório.'),
      newPassword: Yup.string()
        .min(6, 'A senha precisa de no mínimo 6 caractéres.')
        .required('Informar uma senha é obrigatório.'),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref('newPassword')],
          'A confirmação de senha informada não confere com a senha.'
        )
        .required('Informar a confirmação da senha é obrigatório.'),
    });

    try {
      formRef.current.setErrors({});

      await schema.validate(
        {
          email,
          newPassword,
          confirmPassword,
        },
        {
          abortEarly: false,
        }
      );

      dispatch(
        passChangeRequest({ id: data.id, email, newPassword, confirmPassword })
      );
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }

      console.tron.log(`Error: ${err}`);
    }
  };

  const closeModal = () => dispatch(passChangeFail());

  return (
    <Container hidden={!open}>
      <Body>
        <Content>
          <IButton
            key="IButton"
            color="default"
            icon="HiOutlineX"
            onClick={() => closeModal()}
          />
          <Unform
            ref={formRef}
            initialData={{ fullName: data.name, email: data.email }}
            onSubmit={handleSubmit}
          >
            <Input
              name="fullName"
              type="text"
              label="Nome do Cliente:"
              placeholder="Nome Completo do Cliente"
              disabled
            />
            <Input
              name="email"
              type="email"
              label="E-Mail do Cliente:"
              placeholder="E-Mail do Cliente"
              disabled
            />
            <Input
              name="newPassword"
              type="password"
              label="Uma senha bem segura:"
              placeholder="Nova senha"
            />
            <Input
              name="confirmPassword"
              type="password"
              label="Confirme a senha:"
              placeholder="Confirme a senha"
            />

            <SimpleButton
              type="submit"
              color="success"
              margin={{ top: '45px', bottom: '15px' }}
            >
              Trocar senha
            </SimpleButton>
          </Unform>
        </Content>
      </Body>
    </Container>
  );
}

PasswordChange.propTypes = {};

PasswordChange.defaultProps = {};

export default PasswordChange;
