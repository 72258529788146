import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px 0;
`;

export const RadionBtn = styled.div`
  position: relative;
  margin: 0 5px 5px;
  flex: 1 0 18%;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 4px;

  @media only screen and (max-width: 575px) {
    flex: 1 0 100%;
    margin-left: 0;
  }
`;

export const Label = styled.label`
  padding: 20px 15px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;

  &:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: rgb(249, 109, 0);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }

  &:after {
    width: 25px;
    height: 25px;
    content: '';
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 1px 2px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }
`;

export const Input = styled.input`
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;

  &:checked + label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #393e46;
      border-color: #393e46;
    }
  }
`;

export const FormLabel = styled.label`
  position: relative;
  display: block;
  flex: 1 1 100%;
  align-items: center;
  font-size: 1rem;
  padding-left: 0;
  margin-right: 0;
  margin-bottom: 0.55rem;
  color: #222;
`;
