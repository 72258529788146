export const signInRequest = (data, callback) => ({
  type: '@auth/SIGN_IN_REQUEST',
  payload: { ...data, callback },
});

export const signInSuccess = (token, user) => ({
  type: '@auth/SIGN_IN_SUCCESS',
  payload: { token, user },
});

export const signUpRequest = (data, callback) => {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { ...data, callback },
  };
};

export const signFailure = () => ({
  type: '@auth/SIGN_FAILURE',
});

export const signOut = () => ({
  type: '@auth/SIGN_OUT',
});
