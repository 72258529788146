import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from '../../Card';
import SimpleButton from '../../Button/Simple';

import Api from '../../../services/api';
import { setQuestionsPage } from '../../../store/modules/questionnaire/actions';

import { Content, Title, Form } from './styles';
import { Radio } from '../../Form';

const Questions = () => {
  const formRef = useRef();
  const [questionList, setQuestionList] = useState([]);
  const [answers, setAnswers] = useState([]);

  const questionnaire = useSelector((state) => state.questionnaire);
  const dispatch = useDispatch();
  const history = useHistory();

  const options = (v, i) => {
    return [
      {
        id: `q-${v.id}-r-1`,
        label: 'Nunca',
        value: JSON.stringify({ index: i, question_id: v.id, answer: 1 }),
      },
      {
        id: `q-${v.id}-r-2`,
        label: 'Poucas vezes',
        value: JSON.stringify({ index: i, question_id: v.id, answer: 2 }),
      },
      {
        id: `q-${v.id}-r-3`,
        label: 'Metade das vezes',
        value: JSON.stringify({ index: i, question_id: v.id, answer: 3 }),
      },
      {
        id: `q-${v.id}-r-4`,
        label: 'Muitas vezes',
        value: JSON.stringify({ index: i, question_id: v.id, answer: 4 }),
      },
      {
        id: `q-${v.id}-r-5`,
        label: 'Sempre',
        value: JSON.stringify({ index: i, question_id: v.id, answer: 5 }),
      },
    ];
  };

  const fetchQuestions = async (page = 1) => {
    setQuestionList([]);
    setAnswers([]);

    try {
      const { data } = await Api.get(`questions/paginated?page=${page}`);

      if (data.status === 'success') {
        setQuestionList(data.data.data);

        dispatch(
          setQuestionsPage({
            page,
            lastPage: data.data.prev ? data.data.prev.page : null,
            nextPage: data.data.next ? data.data.next.page : null,
          })
        );
      }

      return true;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  const handleChange = (e) => {
    const v = JSON.parse(e.target.value);
    const array = answers;
    const exists = array.findIndex((a) => a && a.question_id === v.question_id);

    if (exists > -1) {
      array[exists] = v;
    } else {
      array[v.index] = v;
    }

    setAnswers(array);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (answers.length < questionList.length) {
      toast.error('Favor responder todas as questões');
      return false;
    }

    const isInvalid = answers.findIndex((el) => typeof el === 'undefined');
    if (isInvalid !== -1) {
      toast.error('Favor responder todas as questões.');
      return false;
    }

    try {
      const { data } = await Api.post('evaluations/questions', {
        evaluation_id: questionnaire.id,
        questions: answers,
      });

      if (data.status !== 'success') {
        toast.error('Houve um erro ao tentar salvar as questões.');
        return false;
      }

      if (questionnaire.nextPage && questionnaire.nextPage !== null) {
        fetchQuestions(questionnaire.nextPage);
      } else {
        history.push('/report');
      }

      return true;
    } catch (error) {
      toast.error(error.message);

      return false;
    }
  };

  useEffect(() => {
    fetchQuestions();
    return () => {};
  }, []);

  return (
    <Card>
      <>
        <Title>
          Passo {questionnaire.nextPage ? questionnaire.page : 'Final'}
        </Title>

        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {questionList.map((q, i) => (
              <Radio
                name={`q${q.id}`}
                label={q.statement}
                options={options(q, i)}
                onChange={handleChange}
              />
            ))}

            <SimpleButton
              color="success"
              type="submit"
              margin={{ top: '25px', bottom: '0' }}
            >
              Próximo
            </SimpleButton>
          </Form>
        </Content>
      </>
    </Card>
  );
};

export default Questions;
