import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#fff';

      case 'info':
        return '#fff';

      case 'success':
        return '#fff';

      case 'warning':
        return 'rgba(0,0,0,.7)';

      case 'danger':
        return '#fff';

      default:
        return '#363636';
    }
  }};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${({ color }) =>
    color !== 'default' ? 'transparent' : '#dbdbdb'};
  background-color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#00d1b2';

      case 'info':
        return '#3298dc';

      case 'success':
        return '#48c774';

      case 'warning':
        return '#ffdd57';

      case 'danger':
        return '#f14668';

      default:
        return 'transparent';
    }
  }};

  height: 2.5em;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  margin: ${({ margin }) => {
    return `${margin.top ? margin.top : 0} ${margin.right ? margin.right : 0} ${
      margin.bottom ? margin.bottom : '25px'
    } ${margin.left ? margin.left : 0}`;
  }};

  cursor: pointer;
  vertical-align: top;
  box-shadow: none;

  -moz-appearance: none;
  -webkit-appearance: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
