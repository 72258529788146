import styled from 'styled-components';
import { Form } from '@unform/web';

import SimpleButton from '~/components/Button/Simple';

export const Image = styled.img`
  width: 60%;
  height: auto;

  margin: 0 auto;
`;

export const Unform = styled(Form)`
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center;
  margin: 16px 0 0 !important;

  min-width: 40%;
  max-width: 50%;

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    min-width: 50%;
    max-width: 70%;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    min-width: 70%;
    max-width: 85%;
  }

  @media only screen and (max-width: 575px) {
    min-width: 85%;
    max-width: 100%;
  }
`;

export const Text = styled.p`
  padding: 0 4px;
  margin-top: 5px;
  color: #f2f2f2;
  text-align: justify;

  a {
    text-decoration: underline;
  }
`;

export const FormInputContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: ${({ grid }) => {
    if (typeof grid !== 'undefined') {
      const size = (100 / 12) * grid;
      return `${size}%`;
    }

    return '100%';
  }};
  padding: 0 4px;
  margin-bottom: 12px;
  text-align: left;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  input {
    display: block;
    width: 100%;
    margin: 0 0 4px !important;

    font-size: 0.95rem !important;
    line-height: 1.4 !important;
  }

  span {
    text-align: left;
  }

  @media only screen and (max-width: 1199px) {
    flex-basis: 100%;
  }
`;

export const FormButtonContainer = styled.div`
  flex: 0 1 100%;
  padding: 0 15px;
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(SimpleButton)`
  padding: 12px 28px;

  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
