import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import Api from '~/services/api';

import Card from '~/components/Card';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import { Input } from '~/components/Form';

import {
  Container,
  Content,
  Title,
  Subtitle,
  Body,
  Unform,
  FormInputContainer,
  Button,
  TextAreaHidden,
} from './styles';

function Companies() {
  const formRef = useRef();
  const textareaRef = useRef();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: null,
  });

  const findCompanies = async (page = 1, name = null) => {
    setLoading(true);

    try {
      const { data } = await Api.get(
        `companies?page=${page}${name !== null ? `&name=${name}` : ''}`
      );

      if (data.status !== 'success') {
        setLoading(false);
        toast.error('Houve um erro ao listar as Empresas.');

        return false;
      }

      setList(data.data.data);
      setPagination({
        page,
        lastPage: data.data.lastPage,
      });
      setLoading(false);

      return true;
    } catch (error) {
      setLoading(false);
      toast.error('Houve um erro ao listar as Empresas.');

      return new Error('Erro ao listar as Empresas.');
    }
  };

  const handlePageChange = (page) => {
    if (page && page >= 1) {
      findCompanies(page);
    }
  };

  const handleFilter = ({ filterName }) => {
    let name = null;

    if (filterName && filterName !== null && filterName !== '') {
      name = filterName;
    }

    findCompanies(1, name);
  };

  const handleReset = () => {
    findCompanies();

    formRef.current.reset();
  };

  const handleLink = useCallback((r) => {
    textareaRef.current.innerText = `https://performance.grossisaudeintegral.com.br/register/${r.link}`;
    textareaRef.current.select();

    document.execCommand('copy');

    toast.info('Link copiado!');
  }, []);

  const headers = [
    {
      title: 'CNPJ',
      label: 'cnpj',
      type: 'cnpj',
      align: 'left',
    },
    {
      title: 'Nome Fantasia',
      label: 'fantasyName',
      type: 'string',
      align: 'left',
    },
    {
      title: 'Pessoas Cad.',
      label: 'userCount',
      type: 'number',
      align: 'center',
    },
    {
      title: 'Ativo',
      label: 'active',
      type: 'bool',
    },
    {
      title: 'Ações',
      actions: [
        {
          type: 'icon',
          label: '',
          title: 'Editar Empresa',
          icon: 'HiOutlinePencil',
          color: 'primary',
          disabled: (r) => !r.active,
          onClick: (r) => history.push(`/companies/edit/${r.id}`),
        },
        {
          type: 'icon',
          label: '',
          title: 'Copiar Link',
          icon: 'HiLink',
          color: 'info',
          disabled: (r) => !r.active,
          onClick: handleLink,
        },
      ],
    },
  ];

  const makeLoadingCard = () => {
    return (
      <Body>
        <Loading />
      </Body>
    );
  };

  const makeTableCard = () => {
    return (
      <>
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
        <Table headers={headers} data={list} />
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
      </>
    );
  };

  useEffect(() => {
    findCompanies();
    return () => {};
  }, []);

  return (
    <Container>
      <Content>
        <Title>Lista de Empresas</Title>

        <Card>
          <>
            <Subtitle>Filtros</Subtitle>
            <Unform ref={formRef} onSubmit={handleFilter} onReset={handleReset}>
              <FormInputContainer>
                <Input
                  className="input-default"
                  label="Nome Fantasia:"
                  name="filterFantasy"
                  placeholder="Nome da Empresa"
                />
              </FormInputContainer>

              <Button
                type="submit"
                color="grossi"
                margin={{ top: '45px', bottom: '15px' }}
              >
                Filtrar
              </Button>
              <Button
                type="reset"
                color="default"
                margin={{ top: 0, bottom: 0, left: '5px' }}
              >
                Limpar
              </Button>
              <Button
                type="button"
                color="primary"
                margin={{ top: 0, bottom: 0, left: 'auto' }}
                onClick={() => history.push('/companies/new')}
              >
                Nova Empresa
              </Button>
            </Unform>
          </>
        </Card>

        <Card>{loading ? makeLoadingCard() : makeTableCard()}</Card>
        <TextAreaHidden ref={textareaRef} />
      </Content>
    </Container>
  );
}

export default Companies;
