import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { setEvaluation, evaluationFailure } from './actions';

export function* createEvaluation({ payload }) {
  try {
    const { profession, challenge } = payload;

    const response = yield call(api.post, 'evaluations', {
      profession,
      challenge,
    });

    const { data } = response.data;

    yield put(setEvaluation({ evaluation_id: data.evaluation.id }));
  } catch (error) {
    toast.error(error.message);
    yield put(evaluationFailure());
  }
}

export default all([
  takeLatest('@evaluation/START_QUESTIONNAIRE', createEvaluation),
]);
