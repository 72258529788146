import styled from 'styled-components';

export const Content = styled.div`
  text-align: left;
`;

export const Form = styled.form`
  display: flex;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-bottom: 2rem;
`;

export const Title = styled.p`
  font-size: 1.45rem;
  text-align: right;
`;
