import produce from 'immer';

const INITIAL_STATE = {
  id: null,
  page: 1,
  nextPage: null,
  lastPage: null,
};

export default function questionnaire(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@evaluation/NEW_EVALUATION': {
        draft.id = null;
        break;
      }
      case '@evaluation/SET_EVALUATION': {
        draft.id = action.payload.evaluation_id;
        break;
      }
      case '@evaluation/SET_PAGE': {
        draft.nextPage = action.payload.nextPage;
        draft.lastPage = action.payload.lastPage;
        draft.page = action.payload.page;
        break;
      }
      case '@evaluation/EVAL_FAIL': {
        draft.id = INITIAL_STATE.id;
        draft.page = INITIAL_STATE.page;
        draft.nextPage = INITIAL_STATE.nextPage;
        draft.lastPage = INITIAL_STATE.lastPage;
        break;
      }

      case '@evaluation/REPORT_FAIL': {
        draft.id = INITIAL_STATE.id;
        draft.page = INITIAL_STATE.page;
        draft.nextPage = INITIAL_STATE.nextPage;
        draft.lastPage = INITIAL_STATE.lastPage;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.id = INITIAL_STATE.id;
        draft.page = INITIAL_STATE.page;
        draft.nextPage = INITIAL_STATE.nextPage;
        draft.lastPage = INITIAL_STATE.lastPage;
        break;
      }
      default:
    }
  });
}
