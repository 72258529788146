import React from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Dashboard from '../pages/Dashboard';
import Questionnaire from '../pages/Questionnaire';
import Report from '../pages/Report';
import Users from '../pages/Users';
import Companies from '../pages/Companies/Report';
import CompaniesForm from '../pages/Companies/Form';
import Evaluations from '../pages/Evaluations';
import GeneralEvaluations from '../pages/Evaluations/General';
import CompanyEvaluations from '../pages/Evaluations/Company';

export default function Routes() {
  return (
    <Switch>
      <PublicRoute path="/" exact component={SignIn} />
      <PublicRoute path="/register" exact component={SignUp} />
      <PublicRoute path="/register/:token" component={SignUp} />

      <PrivateRoute path="/home" component={Dashboard} />
      <PrivateRoute path="/form" component={Questionnaire} />
      <PrivateRoute path="/report" component={Report} />
      <PrivateRoute path="/users" component={Users} />
      <PrivateRoute path="/companies" exact component={Companies} />
      <PrivateRoute path="/companies/new" component={CompaniesForm} />
      <PrivateRoute path="/companies/edit/:id" component={CompaniesForm} />
      <PrivateRoute path="/evaluations" exact component={Evaluations} />
      <PrivateRoute
        path="/evaluations/general"
        component={GeneralEvaluations}
      />
      <PrivateRoute
        path="/evaluations/company"
        component={CompanyEvaluations}
      />
    </Switch>
  );
}
