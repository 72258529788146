import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    -webkit-font-smoothing: antialiased;
    height: 100%;
    overflow: hidden;
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    font-size: 4.2rem;
    line-height: 110%;
    margin: 2.8rem 0 1.68rem 0;
  }

  h2 {
    font-size: 3.56rem;
    line-height: 110%;
    margin: 2.3733333333rem 0 1.424rem 0;
  }

  h3 {
    font-size: 2.92rem;
    line-height: 110%;
    margin: 1.9466666667rem 0 1.168rem 0;
  }

  h4 {
    font-size: 2.28rem;
    line-height: 110%;
    margin: 1.52rem 0 .912rem 0;
  }

  h5 {
    font-size: 1.64rem;
    line-height: 110%;
    margin: 1.0933333333rem 0 .656rem 0;
  }

  h6 {
    font-size: 1.15rem;
    line-height: 110%;
    margin: .7666666667rem 0 .46rem 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
`;
