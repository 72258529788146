import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import socket from 'socket.io-client';

import Api from '~/services/api';

import {
  reportProcessStart,
  reportProcessFinish,
} from '~/store/modules/report/actions';

import Card from '~/components/Card';
import Loading from '~/components/Loading';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import { Input, MaskedInput } from '~/components/Form';

import {
  Container,
  Content,
  Title,
  Subtitle,
  Body,
  Unform,
  FormInputContainer,
  Button,
} from './styles';

export default function GeneralEvaluations() {
  const url =
    process.env.NODE_ENV !== 'development'
      ? `${process.env.REACT_APP_API}pdfs/`
      : 'http://localhost:3333/pdfs/';

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const formRef = useRef();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: null,
  });

  const makeReport = async (type = 'full', row) => {
    dispatch(reportProcessStart());
    try {
      const { data } = await Api.get(`reports/${type}/${row.id}`);
      if (data.status === 'success') {
        const io = socket(
          process.env.NODE_ENV !== 'development'
            ? `${process.env.REACT_APP_API}`
            : 'http://localhost:3333',
          {
            query: {
              Authorization: token,
            },
            transport: ['websocket'],
          }
        );

        io.on(data.channel, (message) => {
          if (message) {
            if (message.status === 'success') {
              dispatch(reportProcessFinish());
              window.open(url + message.file, '_blank');
            } else if (message.status === 'failure') {
              toast.error(
                'Não foi possível gerar o relatório. Favor tentar novamente.'
              );
              dispatch(reportProcessFinish());
            }
          }

          io.close();
        });
      } else if (data.status === 'failure') {
        toast.error(
          'Não foi possível gerar o relatório. Favor tentar novamente.'
        );
        dispatch(reportProcessFinish());
      }
    } catch (err) {
      toast.error(
        'Não foi possível gerar o relatório. Favor tentar novamente.'
      );
      dispatch(reportProcessFinish());
    }
  };

  const findEvaluations = async (page = 1, name = null, id = null) => {
    setLoading(true);
    try {
      const { data } = await Api.get(
        `evaluations?page=${page}${name !== null ? `&name=${name}` : ''}${
          id !== null ? `&id=${id}` : ''
        }`
      );

      if (data.status !== 'success') {
        toast.error('Houve um erro ao listar as Avaliações.');
        setLoading(false);
        return false;
      }

      setList(data.data.data);
      setPagination({
        page,
        lastPage: data.data.lastPage,
      });
      setLoading(false);

      return true;
    } catch (err) {
      toast.error('Houve um erro ao listar as Avaliações.');
      setLoading(false);
      return new Error('Erro ao listar as avaliações.');
    }
  };

  const handlePageChange = (page) => {
    if (page && page >= 1) {
      findEvaluations(page);
    }
  };

  const handleFilter = ({ filterName, filterId }) => {
    let name = null;
    let id = null;

    if (filterName && filterName !== null && filterName !== '') {
      name = filterName;
    }

    if (filterId && filterId !== null && filterId !== '') {
      id = filterId;
    }

    findEvaluations(1, name, id);
  };

  const handleReset = () => {
    findEvaluations();

    formRef.current.reset();
  };

  useEffect(() => {
    findEvaluations();
    return () => {};
  }, []);

  const headers = [
    {
      title: 'Código',
      label: 'id',
    },
    {
      title: 'Nome',
      label: 'name',
      align: 'left',
    },
    {
      title: 'Ações',
      actions: [
        {
          type: 'icon',
          label: 'Relatório Simples',
          icon: 'HiDocumentReport',
          color: 'primary',
          onClick: (r) => makeReport('simple', r),
        },
        {
          type: 'icon',
          label: 'Relatório Completo',
          icon: 'HiBookOpen',
          color: 'info',
          onClick: (r) => makeReport('full', r),
        },
      ],
    },
  ];

  const makeLoadingCard = () => {
    return (
      <Body>
        <Loading />
      </Body>
    );
  };

  const makeTableCard = () => {
    return (
      <>
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
        <Table headers={headers} data={list} />
        <Pagination
          currentPage={pagination.page}
          totalPages={pagination.lastPage}
          onPageChanged={handlePageChange}
        />
      </>
    );
  };

  return (
    <Container>
      <Content>
        <Title>Lista de Avaliações</Title>

        <Card>
          <Subtitle>Filtros</Subtitle>
          <Unform ref={formRef} onSubmit={handleFilter} onReset={handleReset}>
            <FormInputContainer>
              <MaskedInput
                className="input-default"
                label="Código:"
                name="filterId"
                placeholder="Código da Avaliação"
                mask="999999"
                maskChar=""
              />
            </FormInputContainer>
            <FormInputContainer>
              <Input
                className="input-default"
                label="Nome:"
                name="filterName"
                placeholder="Nome do Cliente"
                background={false}
              />
            </FormInputContainer>

            <Button
              type="submit"
              color="grossi"
              margin={{ top: '45px', bottom: '15px' }}
            >
              Filtrar
            </Button>
            <Button
              type="reset"
              color="default"
              margin={{ top: 0, bottom: 0, left: '5px' }}
            >
              Limpar
            </Button>
          </Unform>
        </Card>

        <Card>{loading ? makeLoadingCard() : makeTableCard()}</Card>
      </Content>
    </Container>
  );
}
