import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV !== 'development'
      ? process.env.REACT_APP_API
      : 'http://localhost:3333',
});

export default api;
