import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Body from './Body';

import { Container, Content } from './styles';

const Table = ({ headers, data }) => {
  return (
    <Container>
      <Content>
        <Header headers={headers} />
        <Body data={data} headers={headers} />
      </Content>
    </Container>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
