import produce from 'immer';

const INITIAL_STATE = {
  open: false,
  data: {},
};

export default function company(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@company/NEW_LINK_SUCCESS':
      return produce(state, (draft) => {
        draft.open = true;
        draft.data = action.payload;
      });

    default:
      return state;
  }
}
