import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  position: relative;
  top: 0;
  width: 100vw;
  background: #f96d00;
  padding: 0 30px;
  z-index: 99;

  @media only screen and (max-width: 575px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    img {
      width: 20%;
      margin-right: 20px;
      padding-right: 20px;

      border-right: 1px solid #eee;

      @media only screen and (max-width: 575px) {
        width: 40%;
        margin-right: 10px;
        padding-right: 10px;
      }
    }

    a {
      font-size: 1em;
      font-weight: bold;
      color: #f2f2f2;
      padding: 1em;
      margin-left: 5px;
      transition: all 250ms ease-in-out;

      &:nth-of-type(1) {
        margin-left: 0px;
      }

      &.active,
      &:hover {
        background-color: ${lighten(0.1)('#f96d00')};
      }

      @media only screen and (max-width: 575px) {
        padding: 1em 0.25em;
      }
    }
  }

  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  height: 64px;
  margin-left: 20px;
  padding-left: 30px;
  border-left: 1px solid #f2f2f2;
  align-items: center;

  div {
    text-align: right;

    strong {
      display: block;
      color: #f2f2f2;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 16px;
      color: #f2f2f2;
    }
  }

  img {
    height: 32px;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  color: #f2f2f2;
  padding: 0;
  border: none;

  font-size: 1rem;
  line-height: 1.5;
`;
