import styled from 'styled-components';

export const Container = styled.thead``;

export const Row = styled.tr``;

export const Cell = styled.th`
  padding: 12px 7px;
  font-size: 14px;
  font-weight: 700;
  text-align: ${({ align }) => align};
  text-transform: uppercase;
  vertical-align: middle;
  border: 0;
  box-sizing: border-box;
`;
