import styled from 'styled-components';
import { Form } from '@unform/web';
import IconButton from '../../Button/Icon';

export const Unform = styled(Form)`
  @media only screen and (max-width: 575px) {
    min-width: 85%;
  }
`;

export const Container = styled.div`
  place-items: center;

  background-color: rgba(0, 0, 0, 0.8);

  min-width: 100vw;
  min-height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;

  ${({ hidden }) => {
    if (hidden) {
      return 'display: none; opacity: 0;';
    }
    return 'display: grid; opacity: 1;';
  }};
`;

export const Body = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  width: 60vw;
  height: auto;
  min-height: 80vh;

  border-radius: 0.4em;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 10px 15px;
`;

export const IButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 15px;
`;
