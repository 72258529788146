import React from 'react';
import { useSelector } from 'react-redux';

import Start from '../../components/Questionnaire/Start';
import Questions from '../../components/Questionnaire/Questions';

import { Container, Content, Title } from './styles';

function Questionnaire() {
  const questionnaire = useSelector((state) => state.questionnaire);

  const handleSubmit = () => {};

  return (
    <Container>
      {questionnaire.id === null ? (
        <>
          <Content>
            <Title>Antes de começarmos</Title>
          </Content>

          <Content>
            <Start />
          </Content>
        </>
      ) : (
        <>
          <Content>
            <Questions onSubmit={handleSubmit} />
          </Content>
        </>
      )}
    </Container>
  );
}

export default Questionnaire;
