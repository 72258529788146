import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';

import RedeNeural from '~/assets/rede-neural.svg';
import { Container, Content, Background } from './styles';
import PasswordChange from '~/components/Modal/PasswordChange';

function DefaultLayout({ children }) {
  return (
    <>
      <Container>
        <Header />
        <Content>{children}</Content>
        <Background>
          <img src={RedeNeural} alt="Grossi - Rede Neural" />
        </Background>
        <PasswordChange />
      </Container>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DefaultLayout;
