import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Header, Title, Body } from './styles';

const Card = ({ children, header, ...rest }) => {
  return (
    <Container style={rest.containerStyle}>
      <Content style={rest.contentStyle}>
        {header ? (
          <Header>
            <Title>{header}</Title>
          </Header>
        ) : null}
        <Body>{children}</Body>
      </Content>
    </Container>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  header: PropTypes.string,
};

Card.defaultProps = {
  header: undefined,
};

export default Card;
