import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Body, Loader } from './styles';

function ModalLoading() {
  const { loading } = useSelector((state) => state.report);

  return (
    <Container hidden={!loading}>
      <Body>
        <Loader />
        Gerando Relatório
      </Body>
    </Container>
  );
}

ModalLoading.propTypes = {};

ModalLoading.defaultProps = {};

export default ModalLoading;
