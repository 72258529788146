import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  transition: all 250ms ease-in-out;

  @media only screen and (max-width: 575px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  background-color: #fff;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  border-radius: 0;
  overflow: hidden;
`;

export const Header = styled.header`
  background-color: transparent;
  align-items: stretch;
  box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, 0.1);
  display: flex;
`;

export const Title = styled.p`
  align-items: center;
  color: #363636;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 0.75rem 1rem;
`;

export const Body = styled.div`
  background-color: transparent;
  padding: 1.5rem;

  @media only screen and (max-width: 575px) {
    padding: 0.75rem;
  }
`;
