import React from 'react';

import LinkButton from '~/components/Button/Link';
import Card from '~/components/Card';

import { Container, Content, Title, Subtitle, Column } from './styles';

export default function Evaluations() {
  return (
    <Container>
      <Content>
        <Title>Avaliações</Title>

        <Column>
          <Card>
            <>
              <Subtitle>Geral</Subtitle>
              <LinkButton
                path="/evaluations/general"
                color="primary"
                margin={{ top: '16px', bottom: '0' }}
              >
                Ver Mais
              </LinkButton>
            </>
          </Card>
        </Column>
        <Column>
          <Card>
            <>
              <Subtitle>Por Empresa</Subtitle>
              <LinkButton
                path="/evaluations/company"
                color="primary"
                margin={{ top: '16px', bottom: '0' }}
              >
                Ver Mais
              </LinkButton>
            </>
          </Card>
        </Column>
      </Content>
    </Container>
  );
}
