import styled from 'styled-components';
import { darken } from 'polished';

const ChooseColor = ({ color }) => {
  switch (color) {
    case 'primary':
      return '#00d1b2 !important';

    case 'info':
      return '#3298dc !important';

    case 'success':
      return '#48c774 !important';

    case 'warning':
      return '#ffdd57 !important';

    case 'danger':
      return '#f14668 !important';

    default:
      return '#555 !important';
  }
};

const ChooseDarkenColor = ({ color }) => {
  switch (color) {
    case 'primary':
      return `${darken(0.03)('#00d1b2')}!important`;

    case 'info':
      return `${darken(0.03)('#3298dc')}!important`;

    case 'success':
      return `${darken(0.03)('#48c774')}!important`;

    case 'warning':
      return `${darken(0.03)('#ffdd57')}!important`;

    case 'danger':
      return `${darken(0.03)('#f14668')}!important`;

    default:
      return `${darken(0.03)('#555')}!important`;
  }
};

export const Container = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: ${ChooseColor};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 2px !important;
  border-color: ${ChooseColor} !important;
  background: none !important;
  background-color: transparent !important;

  height: 2.75em !important;
  padding: 0.375em;
  margin: ${({ margin }) => {
    return `${margin.top ? margin.top : 0} ${margin.right ? margin.right : 0} ${
      margin.bottom ? margin.bottom : 0
    } ${margin.left ? margin.left : 0} !important`;
  }};

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  &:disabled {
    cursor: not-allowed;
    background-color: #c3c3c3 !important;
    border-color: #929292 !important;
    color: #555 !important;

    &:hover {
      background-color: #c3c3c3 !important;
      border-color: #929292 !important;
      color: #555 !important;
      box-shadow: none;
    }
  }

  &:hover {
    color: #ffffff !important;

    background-color: ${ChooseColor};
    border-color: ${ChooseDarkenColor};

    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
      0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }

  & svg {
    width: 2em;
    height: auto;
  }
`;
