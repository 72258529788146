import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 0 100%;
`;

export const Title = styled.h3`
  text-align: left;
`;
