import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Content = styled.div`
  flex: 0 1 100%;
`;

export const Title = styled.h3`
  text-align: left;
  ${(props) => {
    if (props.marginTop) {
      return `margin-top:${props.marginTop}`;
    }

    return '';
  }}
`;

export const Subtitle = styled.h4`
  font-size: 1.5rem;
  text-align: left;
`;
