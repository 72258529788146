import { all, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

export function* addNewCompany({ payload }) {
  try {
    const { cnpj, companyName, fantasyName, internAreas } = payload;

    const response = yield call(api.post, 'companies', {
      cnpj,
      companyName,
      fantasyName,
      internAreas,
    });

    if (response.data.status === 'success') {
      toast.success('Cadastro da Empresa realizado com sucesso.');
      payload.callback();
    } else {
      toast.error('Falha no cadastro, verifique seus dados.');
    }
  } catch (err) {
    if (typeof err.response.data.errors !== 'undefined') {
      toast.error(err.response.data.errors[0]);
    } else {
      toast.error('Falha no cadastro, verifique seus dados.');
    }
  }
}

export function* updateCompany({ payload }) {
  try {
    const { id, cnpj, companyName, fantasyName, internAreas } = payload;

    const response = yield call(api.put, `companies/${id}`, {
      cnpj,
      companyName,
      fantasyName,
      internAreas,
    });

    if (response.data.status === 'success') {
      toast.success('Dados da Empresa atualizados com sucesso.');
      payload.callback();
    } else {
      toast.error('Falha na atualização, verifique seus dados.');
    }
  } catch (err) {
    if (typeof err.response.data.errors !== 'undefined') {
      toast.error(err.response.data.errors[0]);
    } else {
      toast.error('Falha na atualização, verifique seus dados.');
    }
  }
}

export default all([
  takeLatest('@company/ADD_NEW_COMPANY_REQUEST', addNewCompany),
  takeLatest('@company/UPDATE_COMPANY_REQUEST', updateCompany),
]);
