import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Api from '~/services/api';

import Card from '~/components/Card';
import Loading from '~/components/Loading';
import { Input, MaskedInput } from '~/components/Form';
import IconButton from '~/components/Button/Icon';

import {
  addNewCompanyRequest,
  updateCompanyRequest,
} from '../../../store/modules/company/actions';

import {
  Container,
  Content,
  Title,
  Body,
  Unform,
  FieldsetForm,
  FieldsetLegendForm,
  FormInputContainer,
  FormInputButtonContainer,
  FormButtonContainer,
  Button,
  FormListContainer,
} from './styles';

function CompaniesForm() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [internAreaList, setInternAreaList] = useState([]);
  const [company, setCompany] = useState({
    cnpj: '',
    companyName: '',
    fantasyName: '',
  });

  const { id } = useParams();

  const findCompany = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await Api.get(`companies/${id}`);

      if (data.status !== 'success') {
        setLoading(false);
        toast.error('Houve um erro ao listar as Empresas.');

        return false;
      }

      const { data: companyData } = data;

      const cs = {
        cnpj: companyData.cnpj,
        companyName: companyData.companyName,
        fantasyName: companyData.fantasyName,
      };

      setCompany(cs);
      setInternAreaList(companyData.InternAreas);
      setLoading(false);

      return true;
    } catch (error) {
      setLoading(false);
      toast.error('Houve um erro ao listar as Empresas.');

      return new Error('Erro ao listar as Empresas.');
    }
  }, [id]);

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleCnpj = (value) => {
    const cnpj = value.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    const match = cnpj.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    const calc = (x) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    if (digit1 !== digits[1]) return false;

    return true;
  };

  const handleAddInternArea = () => {
    const input = formRef.current.getFieldValue('internAreas');
    const array = [].concat(internAreaList);

    array.push({
      id: null,
      name: input,
    });

    setInternAreaList(array);
    formRef.current.setFieldValue('internAreas', null);
  };

  const handleRemoveInternArea = useCallback(
    (item) => {
      const index = internAreaList.indexOf(item);

      if (index === -1) {
        return;
      }

      const array = internAreaList.filter((v, i) => i !== index);

      setInternAreaList(array);
    },
    [internAreaList]
  );

  const handleSuccessForm = useCallback(() => {
    history.push('/companies');
  }, [history]);

  const handleFormSubmit = async ({ cnpj, companyName, fantasyName }) => {
    const schema = Yup.object().shape({
      cnpj: Yup.string()
        .required('Informar o CNPJ é obrigatório.')
        .test('is-valid', 'CNPJ informado não é válido.', handleCnpj),
      companyName: Yup.string().required(
        'Informar a Razão Social é obrigatório.'
      ),
      fantasyName: Yup.string().required(
        'Informar o Nome Fantasia é obrigatório.'
      ),
      internAreas: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(
              'Favor informar o nome da Área Interna.'
            ),
          })
        )
        .min(1, (min) => `Deve ser informada no mínimo ${min} Área Interna`)
        .required('Deve ser informada no mínimo 1 Área Interna.'),
    });

    try {
      formRef.current.setErrors({});

      await schema.validate(
        {
          cnpj,
          companyName,
          fantasyName,
          internAreas: internAreaList,
        },
        {
          abortEarly: false,
        }
      );

      const unformattedCnpj = cnpj.replace(/[^\d]+/g, '');

      if (id) {
        dispatch(
          updateCompanyRequest(
            {
              id,
              cnpj: unformattedCnpj,
              companyName,
              fantasyName,
              internAreas: internAreaList,
            },
            handleSuccessForm
          )
        );
      } else {
        dispatch(
          addNewCompanyRequest(
            {
              cnpj: unformattedCnpj,
              companyName,
              fantasyName,
              internAreas: internAreaList,
            },
            handleSuccessForm
          )
        );
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const makeLoadingCard = () => {
    return (
      <Body>
        <Loading />
      </Body>
    );
  };

  useEffect(() => {
    if (id) {
      findCompany();
    }
    return () => {};
  }, [findCompany, id]);

  return (
    <Container>
      <Content>
        <Title>{id ? 'Editar' : 'Nova'} Empresas</Title>

        <Card>
          {loading ? (
            makeLoadingCard()
          ) : (
            <Unform
              ref={formRef}
              initialData={company}
              onSubmit={handleFormSubmit}
              onReset={handleReset}
            >
              <FieldsetForm>
                <FieldsetLegendForm>Dados Básicos</FieldsetLegendForm>

                <FormInputContainer>
                  <MaskedInput
                    className="input-default"
                    label="CNPJ:"
                    name="cnpj"
                    placeholder="CNPJ da Empresa"
                    mask="99.999.999/9999-99"
                  />
                </FormInputContainer>
                <FormInputContainer>
                  <Input
                    className="input-default"
                    label="Razão Social:"
                    name="companyName"
                    placeholder="Razão Social da Empresa"
                  />
                </FormInputContainer>
                <FormInputContainer>
                  <Input
                    className="input-default"
                    label="Nome Fantasia:"
                    name="fantasyName"
                    placeholder="Nome Fantasia da Empresa"
                  />
                </FormInputContainer>
              </FieldsetForm>

              <FieldsetForm>
                <FieldsetLegendForm>Dados Complementares</FieldsetLegendForm>

                <FormInputButtonContainer>
                  <Input
                    className="input-default"
                    label="Área Interna:"
                    name="internAreas"
                    placeholder="Informe uma área interna da empresa"
                  />
                  <IconButton
                    type="button"
                    color="info"
                    icon="HiPlus"
                    title="Adicionar"
                    label=""
                    onClick={() => handleAddInternArea()}
                  />
                </FormInputButtonContainer>
                <FormListContainer>
                  <ul>
                    {internAreaList.map((item, index) => (
                      <li key={item.name + index}>
                        <span>- {item.name}</span>
                        <IconButton
                          type="button"
                          color="default"
                          icon="HiMinusSm"
                          title="Remover"
                          label=""
                          onClick={() => handleRemoveInternArea(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </FormListContainer>
              </FieldsetForm>

              <FormButtonContainer>
                <Button
                  type="submit"
                  color="success"
                  margin={{ top: 0, bottom: 0, left: '5px' }}
                >
                  Salvar
                </Button>
                <Button
                  type="reset"
                  color="default"
                  margin={{ top: 0, bottom: 0, left: '5px' }}
                >
                  Limpar
                </Button>
              </FormButtonContainer>
            </Unform>
          )}
        </Card>
      </Content>
    </Container>
  );
}

export default CompaniesForm;
