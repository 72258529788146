import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Cell } from './styles';

const Header = ({ headers }) => {
  const TextCell = (h) => (
    <Cell key={`${h.title}`} align={h.align || 'center'}>
      {typeof h.title === 'string' ? h.title.toUpperCase() : h.title}
    </Cell>
  );

  const ActionCell = (h) => (
    <Cell
      key={`${h.title}`}
      align={h.align || 'center'}
      colSpan={h.actions.length}
    >
      {typeof h.title === 'string' ? h.title.toUpperCase() : h.title}
    </Cell>
  );

  const MakeCell = (h) => {
    if (!h.actions) {
      return TextCell(h);
    }

    return ActionCell(h);
  };

  return (
    <Container>
      <Row>{headers.map((h) => MakeCell(h))}</Row>
    </Container>
  );
};

Header.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Header;
