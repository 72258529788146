import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Switch, Label, List, Item } from './styles';

const SwitchInput = ({ name, options, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <List>
      {options.map((option, index) => (
        <Item key={option.id}>
          <Switch
            defaultChecked={defaultValue.find((dv) => dv === option.id)}
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            value={option.value}
            type="checkbox"
            id={option.id}
            className="switch"
            {...rest}
          />
          <Label
            htmlFor={option.id}
            dangerouslySetInnerHTML={{ __html: option.label }}
          />
          {error && <span>{error}</span>}
        </Item>
      ))}
    </List>
  );
};

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SwitchInput;
