import React from 'react';

import LinkButton from '../../components/Button/Link';
import FlowText from '../../components/Text/FlowText';

import { Container, Content, Title } from './styles';

export default function Dashboard() {
  return (
    <Container>
      <Content>
        <Title marginTop="0">Motivação e Performance</Title>

        <FlowText align="justify">
          Esta ferramenta de análise de competências de performance reúne
          algumas dicas importantes para você aprimorar e desenvolver conquistas
          autênticas em áreas âncora da vida. A partir das suas respostas ao
          questionário, estabelecemos uma média móvel entre todos os
          participantes de nossa base de dados da Grossi. Agrupamos os
          comportamentos de performance, bem como as melhores práticas para uma
          vida plena em 10 grandes competências. Acreditamos em transformações
          que integrem as dimensões fundantes da vida, em sintonia com as áreas
          pessoal e profissional, combinando motivação e performance na
          construção de hábitos.
        </FlowText>
        <Title>Os Nossos Objetivos</Title>
        <FlowText align="justify">
          A partir das suas respostas, conseguimos mapear dez grandes
          competências de desempenho, agrupadas em quatro perspectivas de ação:
          o mercado, o comportamento, a técnica e os resultados. O objetivo é
          você refinar ainda mais sua atuação de desempenho. Combinamos, através
          das perguntas, traços humanos-comportamentais e técnico-operacionais.
        </FlowText>
        <FlowText align="justify">
          Das dez competências, você terá acesso aos seus quatro maiores
          desafios de melhoria. O objetivo desta ferramenta é servir como um GPS
          para o aprimoramento de sua prática diária dentro de um programa de
          mentoria aqui na Grossi Saúde Integral. A resposta que procuramos é:
          quais pontos você pode reforçar na experiência de fazer-se uma pessoa
          integral? E, ainda: como aprimorar seu trabalho de forma objetiva e
          direta, pautado pelas áreas de alta performance?
        </FlowText>

        <LinkButton path="/form" color="primary" margin={{ top: '35px' }}>
          Iniciar o Questionário
        </LinkButton>
      </Content>
    </Container>
  );
}
