import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Label, Input, RadionBtn, FormLabel, Container } from './styles';

const Radio = forwardRef(({ name, options, onChange, ...rest }) => {
  return (
    <Container>
      <FormLabel>{rest.label}</FormLabel>
      {options.map((option) => (
        <RadionBtn>
          <Input
            id={option.id}
            value={option.value}
            type="radio"
            name={name}
            onChange={onChange}
            {...rest}
          />
          <Label htmlFor={option.id} key={option.id}>
            {option.label}
          </Label>
        </RadionBtn>
      ))}
    </Container>
  );
});

Radio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Radio.defaultProps = {};

export default Radio;
