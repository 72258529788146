import styled from 'styled-components';

export const Container = styled.tbody``;

export const Row = styled.tr`
  position: relative;
`;

export const Cell = styled.td`
  padding: 10px 7px;
  font-size: 12px;
  font-weight: 300;
  text-align: ${({ align }) => align};
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  box-sizing: border-box;
  width: auto;

  ${({ width }) => (width ? `max-width: ${width};` : '')}

  @media only screen and (max-width: 575px) {
    padding: 7.5px 5px;
  }
`;
