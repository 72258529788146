import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const SimpleButton = ({ children, color, margin, ...rest }) => {
  return (
    <Container color={color} margin={margin} {...rest}>
      {children}
    </Container>
  );
};

SimpleButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  margin: PropTypes.shape({}),
};

SimpleButton.defaultProps = {
  color: 'default',
  margin: {},
};

export default SimpleButton;
